<template>
  <validation-provider :name="label" :rules="rules" v-slot="validationContext">
    <b-form-group :label="label" :label-for="id">
      <b-form-select :name="id" hidden :state="getValidationState(validationContext)" :aria-describedby="`${id}-feedback`" :value="value" :id="id"></b-form-select>
      <v-select :disabled="disabled" @option:selected="changed" @option:deselected="deselected" @search:focus="openSelect" :components="{Deselect}" multiple :class="{'invalid': validationContext.errors[0]}" :value="value" @input="handleInput" :options="data" label="label" :reduce="e => e.value" @search="onSearchData">
        <template v-slot:no-options="{search,searching}">
          <template v-if="searching">
            Tidak ada {{label}} ditemukan untuk "<em>{{search}}</em>".
          </template>
          <em style="opacity:0.8;" v-else>Ketik untuk mulai mencari {{label}}.</em>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">🔽</span>
        </template>
      </v-select>
      <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";

import {BFormGroup,BFormInvalidFeedback,BFormSelect} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import axios from '@axios'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', id)

export default {
  props:{
    label:{
      required: true,
      type: String
    },
    url:{
      required: true,
      type: String
    },
    value:{
      required: false,
      type: Array
    },
    rules:{
      required: false,
      type: Object
    },
    disabled:{
      type:Boolean,
      default: false
    },
    queryparams:{
      type:Object
    },
    filterId:Array
  },
  data(){
    return {
      Deselect:{
        render: createElement => createElement('span', '✖️')
      },
      search:'',
      data:[],
      selected:[]
    }
  },
  computed:{
    id(){
      return _.camelCase(this.label)
    }
  },
  components:{
    BFormGroup,
    vSelect,
    ValidationProvider,
    BFormInvalidFeedback,
    BFormSelect
  },
  methods:{
    openSelect(){
      this.loadData()
      // const value = this.value.length
      // const data = this.data.length
      // if (!value && !data) this.loadData()
    },
    changed(val){
      this.selected = val
      this.$emit('value-changed',val)
    },
    deselected(val){
      // console.log(this.selected)
      this.selected = _.filter(this.selected,(n) => n.value!=val.value)
      this.$emit('value-changed',this.selected)
    },
    handleInput(value){
      this.$emit('input', value)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    initOption(value){
      const existing = this.data
      const data = _.uniqBy(_.flatten([...existing,value]),'value')
      this.selected = _.uniqBy(value,'value')
      this.data = data
    },
    async loadData(search=''){
      const value = this.value
      const alreadyassign = this.data.filter(e => value.includes(e.value))
      try {
        let { data:result } = await axios.get(this.url,{params:{name:search,...this.queryparams}})
        let data = result
        if(this.filterId && !this.isPusat) data = _.filter(result, o => _.includes(this.filterId, o.value))
        this.data = _.uniqBy([...data,...alreadyassign],'value')
        return Promise.resolve(data)
      } catch (error) {
        this.handleError(error)
        return Promise.reject(error)
      }
    },
    onSearchData(search,loading){
      if(search.length){
        loading(true);
        this.searchData(loading,search,this)
      }
    },
    searchData: _.debounce((loading,search,vm) => {
      vm.loadData(search).then(() => {
        loading(false)
      })
    },350),
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .invalid .vs__dropdown-toggle {
    border-color: #ea5455;
  }
  .valid .vs__dropdown-toggle {
    border-color: #28c76f;
  }
</style>